<template>
  <div class="change-password">
    <div class="pb-3 change-password-user-logo">
      <img src="@/assets/images/UserLOGO.svg" alt="logo" />
    </div>
    <div class="card change-password-card">
      <div class="card-body p-4">
        <div class="change-password-logo">
          <img src="@/assets/images/Logo.svg" alt="logo" />
        </div>
        <h1 class="text-center h4 fw-light">{{ $t("__changeYourPassword") }}</h1>
        <form @submit.prevent="submitForm">
          <div class="mb-3">
            <label for="changePassword" class="form-label">{{
              $t("__newPassword")
            }}</label>
            <div class="input-group">
              <input
                v-model="new_password"
                id="changePassword"
                :type="show_password ? 'text' : 'password' "
                class="form-control"
                :class="{ 'is-invalid': is_invalid }"
                :placeholder="$t('__enterYouNewPassword')"
                required
              />
              <span class="input-group-text show-password" role="button" @click="showedPassword">
                <i class="bi" :class="show_password ? 'bi-eye-slash-fill' : 'bi-eye-fill'"></i>
              </span>
            </div>
          </div>
          <div class="mb-3">
            <label for="changePasswordAgain" class="form-label">{{
              $t("__retypeNewPassword")
            }}</label>
            <div class="input-group">
              <input
                v-model="new_password_again"
                id="changePasswordAgain"
                :type="show_password ? 'text' : 'password' "
                class="form-control"
                :class="{ 'is-invalid': is_invalid }"
                :placeholder="$t('__pleaseRetypeNewPassword')"
                required
              />
              <span class="input-group-text show-password" role="button" @click="showedPassword">
                <i class="bi" :class="show_password ? 'bi-eye-slash-fill' : 'bi-eye-fill'"></i>
              </span>
            </div>
          </div>
          <div class="text-danger text-center" v-if="is_invalid">
            {{ feedback_message }}
          </div>
          <button
            type="submit"
            class="btn btn-primary rounded-pill change-password-submit mt-3"
          >
            {{ $t("__changePassword") }}
          </button>
        </form>
      </div>
    </div>
    <small class="p-3 d-block text-center text-dark fw-light"
      >©
        {{ new Date().getFullYear() + " " + $t("__HumetricsInc") }} All
        rights reserved.</small
    >
  </div>
</template>

<script>
import i18n from '@/lang/lang.js'
import { auth } from '@/http/api/auth.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'ChangePassword',
  data () {
    return {
      new_password: null,
      new_password_again: null,
      feedback_message: null,
      is_invalid: false,
      user_id: null,
      show_password: false
    }
  },
  computed: {
    ...mapState(['token'])
  },
  created () {
    this.getToken()
  },
  methods: {
    ...mapMutations(['Loaded', 'Loading', 'getToken']),
    showedPassword () {
      this.show_password = !this.show_password
    },
    submitForm () {
      const vm = this
      if (vm.new_password !== vm.new_password_again) {
        vm.is_invalid = true
        vm.feedback_message = i18n.t('__changePasswordDifferent')
      } else {
        vm.Loading()
        auth
          .changePassword({ password: vm.new_password }, vm.token)
          .then((res) => {
            const feaback = res.data
            if (res.status <= 201 && feaback.status === 'success') {
              vm.$router.push({ name: 'Dashboard' })
            } else {
              vm.is_invalid = true
              vm.feedback_message = feaback.errMsg
            }
            vm.Loaded()
          })
          .catch((err) => {
            vm.is_invalid = true
            vm.feedback_message = err
            vm.Loaded()
          })
      }
    }
  }
}
</script>

<style lang="scss">
.change-password {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  &-user-logo {
    max-width: 150px;
    width: 40%;
    margin: auto;
    img {
      width: 100%;
    }
  }
  &-logo {
    max-width: 250px;
    width: 70%;
    margin: 20px auto;
    img {
      width: 100%;
    }
  }
  &-forgot-password {
    text-decoration: none;
    font-size: 14px;
    &:focus {
      box-shadow: none;
    }
  }
  &-card {
    max-width: 360px;
    width: 90%;
    margin: auto;
    border-radius: 10px;
  }
  &-submit {
    width: 100%;
    color: $white;
    transition: box-shadow 0.3s ease-in-out;
    background: $linear-gradient;
    box-shadow: 0px 4px 4px $shadow-color;
    &:hover {
      color: $white;
      box-shadow: 0px 7px 7px $shadow-color;
    }
    &:focus {
      color: $white;
    }
  }
  .show-password{
    background-color: #fff;
    border: none;
    color: $gray;
  }
}
</style>
